import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPageResourceFetcher } from './IPageResourceFetcher'
import {
	CurrentRouteInfoSymbol,
	InitialAssetsSymbol,
	SiteAssetsClientSym,
	ViewerModel,
	ViewerModelSym,
} from '@wix/thunderbolt-symbols'
import { SiteAssetsClientAdapter } from 'thunderbolt-site-assets-client'
import { InitialAssets } from '../../types/InitialAssets'
import { errorPagesIds } from '@wix/thunderbolt-commons'
import { ICurrentRouteInfo } from 'feature-router'

type ResourceFetcherFactory = (
	viewerModel: ViewerModel,
	siteAssetsClient: SiteAssetsClientAdapter,
	initialAssets: InitialAssets,
	currentRouteInfo: ICurrentRouteInfo
) => IPageResourceFetcher

export const resourceFetcher: ResourceFetcherFactory = (
	viewerModel,
	siteAssetsClient,
	initialAssets,
	currentRouteInfo
) => ({
	fetchResource(pageCompId, resourceType, fallbackStrategy) {
		const key = `${resourceType}_${pageCompId}`
		if (initialAssets.siteAssets[key]) {
			return Promise.resolve(initialAssets.siteAssets[key])
		}
		const {
			siteAssets: { modulesParams, siteScopeParams, beckyExperiments, staticHTMLComponentUrl },
			deviceInfo,
			mode: { qa: qaMode },
		} = viewerModel

		const moduleParams = modulesParams[resourceType]
		const isErrorPage = !!errorPagesIds[pageCompId]

		const pageJsonFileNames = siteScopeParams.pageJsonFileNames
		const pageJsonFileName =
			pageJsonFileNames[pageCompId] || currentRouteInfo.getCurrentRouteInfo()?.pageJsonFileName

		return siteAssetsClient.execute(
			{
				moduleParams,
				siteScopeParams,
				pageCompId,
				beckyExperiments,
				deviceInfo,
				staticHTMLComponentUrl,
				qaMode,
				...(pageJsonFileName ? { pageJsonFileName } : {}),
				...(isErrorPage
					? {
							pageCompId: isErrorPage ? 'masterPage' : pageCompId,
							errorPageId: pageCompId,
							pageJsonFileName: pageJsonFileNames.masterPage,
					  }
					: {}),
			},
			fallbackStrategy
		)
	},
	getResourceUrl(pageCompId, resourceType): string {
		const {
			siteAssets: { modulesParams, siteScopeParams, beckyExperiments, staticHTMLComponentUrl },
			deviceInfo,
			mode: { qa: qaMode },
		} = viewerModel

		const moduleParams = modulesParams[resourceType]
		const isErrorPage = !!errorPagesIds[pageCompId]

		const pageJsonFileNames = siteScopeParams.pageJsonFileNames
		const pageJsonFileName =
			pageJsonFileNames[pageCompId] || currentRouteInfo.getCurrentRouteInfo()?.pageJsonFileName

		return siteAssetsClient.calcPublicModuleUrl({
			moduleParams,
			pageCompId,
			siteScopeParams,
			beckyExperiments,
			deviceInfo,
			staticHTMLComponentUrl,
			qaMode,
			...(pageJsonFileName ? { pageJsonFileName } : {}),
			...(isErrorPage
				? {
						pageCompId: isErrorPage ? 'masterPage' : pageCompId,
						errorPageId: pageCompId,
						pageJsonFileName: pageJsonFileNames.masterPage,
				  }
				: {}),
		})
	},
})

export const PageResourceFetcher = withDependencies<IPageResourceFetcher>(
	[ViewerModelSym, SiteAssetsClientSym, InitialAssetsSymbol, CurrentRouteInfoSymbol],
	resourceFetcher
)
