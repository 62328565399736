import { withDependencies } from '@wix/thunderbolt-ioc'
import { DomReadySymbol, IAppWillMountHandler } from '@wix/thunderbolt-symbols'

export const createDomReadyPromise = () =>
	new Promise((resolve) => {
		if (document.readyState === 'complete' || document.readyState === 'interactive') {
			verifySiteContainer(resolve)
		} else {
			document.addEventListener('DOMContentLoaded', () => verifySiteContainer(resolve))
		}
	})

export const WaitForDomReady = withDependencies<IAppWillMountHandler>([DomReadySymbol], (domReady) => ({
	appWillMount: () => domReady,
}))

function verifySiteContainer(resolve: Function): void {
	const ssrReturnedBody = typeof window.clientSideRender !== 'undefined'
	if (!ssrReturnedBody) {
		window.clientSideRender = true
		window.santaRenderingError = window.santaRenderingError || {
			errorInfo: 'body failed to render',
		}

		const siteContainer = window.document.createElement('DIV')
		siteContainer.setAttribute('id', 'site-container')
		window.document.body.appendChild(siteContainer)
	}
	resolve()
}
