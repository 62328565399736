import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle, Translate } from '@wix/thunderbolt-symbols'
import { TranslationsImpl, TranslateBinder } from './translations'
import { getTranslationUrl } from './translationsUrl'

export const site: ContainerModuleLoader = (bind) => {
	bind(Translate).to(TranslationsImpl)
	bind(LifeCycle.AppWillMountHandler).to(TranslateBinder)
}

// Public Utils
export { getTranslationUrl }
