import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import { PageResourceFetcherSymbol, PageStyleLoaderSymbol } from './symbols'
import { IPageResourceFetcher } from './IPageResourceFetcher'
import { ClientPageStyleLoader, ILoadPageStyle, ServerPageStyleLoader } from './PageStyleLoader'
import { IPageAssetsLoader, PageAssets, PageAssetsLoaderSymbol } from '@wix/thunderbolt-symbols'
import { PageResourceFetcher } from './PageResourceFetcher'

type PageAssetsLoaderFactory = (
	pageResourceFetcher: IPageResourceFetcher,
	pageStyleLoader: ILoadPageStyle
) => IPageAssetsLoader

const pageAssetsLoaderImplFactory: PageAssetsLoaderFactory = (pageResourceFetcher, pageStyleLoader) => {
	const assetsCache: Record<string, PageAssets> = {}

	const createPageAssets = (pageCompId: string): PageAssets => {
		const addCssPromise = pageStyleLoader.load(pageCompId)
		const structure = pageResourceFetcher.fetchResource(pageCompId, 'structure', 'enable')
		const props = pageResourceFetcher.fetchResource(pageCompId, 'props', 'enable')
		return {
			components: structure.then(({ components }) => components),
			features: structure.then(({ features }) => features),
			siteFeaturesConfigs: structure.then(({ siteFeaturesConfigs }) => siteFeaturesConfigs),
			props,
			css: addCssPromise,
		}
	}

	return {
		load: (pageCompId: string) => {
			assetsCache[pageCompId] = assetsCache[pageCompId] || createPageAssets(pageCompId)
			return assetsCache[pageCompId]
		},
	}
}

const PageAssetsLoaderImpl = withDependencies(
	[PageResourceFetcherSymbol, PageStyleLoaderSymbol],
	pageAssetsLoaderImplFactory
)

export const site: ContainerModuleLoader = (bind) => {
	bind<IPageAssetsLoader>(PageAssetsLoaderSymbol).to(PageAssetsLoaderImpl)
	bind<IPageResourceFetcher>(PageResourceFetcherSymbol).to(PageResourceFetcher)
	if (process.env.browser) {
		bind<ILoadPageStyle>(PageStyleLoaderSymbol).to(ClientPageStyleLoader)
	} else {
		bind<ILoadPageStyle>(PageStyleLoaderSymbol).to(ServerPageStyleLoader)
	}
}
