import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	IAppWillMountHandler,
	Context,
	IContext,
	Fetch,
	Translate,
	ITranslate,
	ITranslationsFetcher,
	ILanguage,
	IFetchApi,
	LanguageSymbol,
	FeatureStateSymbol,
} from '@wix/thunderbolt-symbols'
import { getTranslationUrl } from './translationsUrl'
import { Translations } from './types'
import { IFeatureState } from 'thunderbolt-feature-state'

type TranslationsFeatureState = { translations: Translations | Promise<Translations> }
const translationsImpl = (
	fetchApi: IFetchApi,
	{ userLanguage, translationsBaseUrl }: ILanguage,
	featureState: IFeatureState<TranslationsFeatureState>
): (() => Promise<ITranslate>) => {
	return async () => {
		if (!featureState.get()?.translations) {
			featureState.update(() => ({
				translations: fetchApi.getJson<Translations>(getTranslationUrl(translationsBaseUrl, userLanguage)),
			}))
		}

		const translations = await featureState.get().translations

		return (featureNamespace, key, defaultValue) =>
			(translations[featureNamespace] && translations[featureNamespace][key]) || defaultValue
	}
}

const translateBinder = (translationsFetcher: ITranslationsFetcher, context: IContext): IAppWillMountHandler => ({
	async appWillMount() {
		context.extend({ translate: await translationsFetcher() })
	},
})

export const TranslationsImpl = withDependencies(
	[Fetch, LanguageSymbol, named(FeatureStateSymbol, 'translations')],
	translationsImpl
)
export const TranslateBinder = withDependencies([Translate, Context], translateBinder)
